body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #423d40; /* grayish */
  font-family: 'Comic Neue', cursive;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bangers', cursive;
  color: #ffdc69; /* */
}

.large-heading {
  font-size: 3em; 
}

a {
  color: #0077c0; /* blue */
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.character-image {
  border: 2px solid black;
  box-shadow: 5px 5px 0px 0px black;
}

.circular {
  border-radius: 50%;
 }

.character-card {
  border: 2px solid black;
  box-shadow: 5px 5px 0px 0px black;
}

.card-container {
  position: relative; /* Ensure the speech bubble is positioned relative to the card */
}

.speech-bubble {
  display: none;
  position: absolute;
  background: white;
  border-radius: 50% / 50%; /* Elliptical shape */
  padding: 2em 2em; /* Adjust padding to make the bubble tighter */
  color: #201c27;
  font-size: 0.75em;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  top: -80px; /* Adjust to position the bubble above the card */
  left: 35%;
  transform: translateX(-50%);
  max-width: 80%; /* Limit the width of the bubble */
  white-space: nowrap; /* Prevent text wrapping */
}

.card-container:hover .speech-bubble {
  display: block;
  opacity: 1;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: -10px; /* Adjust to position the tail of the bubble */
  left: 50%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 30px solid white;
  transform: translateX(-50%) rotate(15deg); /* Adjust rotation to curve the tail */
}

.speech-bubble:before {
  content: '';
  position: absolute;
  bottom: -20px; /* Adjust to position the tail of the bubble */
  left: 50%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 30px solid white;
  transform: translateX(-50%) rotate(-15deg); /* Adjust rotation to curve the tail */
}

.navbar {
  background-color: #bbb1cd; /* */
  font-family: 'Comic Neue', cursive;
  padding: 1em;
  font-weight: bold;
}

.nav-link {
  margin-right: 1em;
}

.navbar-brand,
.nav-link {
    color: #201c27; /* white */
}

.nav-link:hover {
  color: #ffde59; /* yellow */
}

.nav-link.active {
  color: #ff6e40; /* red */
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f8f9f9;
  color: black;
  text-align: center;
}

.orange-background {
  background-color:#da521d;
  font-weight: bold;
}

